<template>
    <div class="invitationCode">
        <common-nav-bar title="测试报告详情"></common-nav-bar>
        <div>
            <highcharts :options="seLeftChart"></highcharts>
            <highcharts :options="seRightChart"></highcharts>
        </div>
    </div>
</template>

<script>
import { getSeRecord, getDbhlList } from '@/services/se_record';
import { toast } from '@/util';
import CommonNavBar from '@/components/common-nav-bar';
import { Chart } from 'highcharts-vue';
export default {
    // name: "index"
    components: {
        CommonNavBar,
        highcharts: Chart,
    },
    data() {
        return {
            id: 0,
            dbhlData: [],
            seRecord: {},
            seLeftChart: {},
            seRightChart: {},
        };
    },
    async created() {
        this.id = this.$route.query.id;
        await Promise.all([this.getSeRecord(), this.getDbhlList()]);
        this.buildChartData();
    },
    methods: {
        async getSeRecord() {
            const { code, message, data } = await getSeRecord(this.id);
            if (!code) {
                toast(message);
            }
            this.seRecord = data;
        },
        async getDbhlList() {
            const { code, message, data } = await getDbhlList();
            if (!code) {
                toast(message);
            }
            this.dbhlData = data;
        },
        buildChartData() {
            const seRecord = this.seRecord;
            let seLeftData = [];
            let seRightData = [];
            if (seRecord) {
                this.dbhlData.forEach(item => {
                    let wave = item.wave;
                    let hl = item.hl;
                    seLeftData.push((seRecord['l' + wave] * 1 + hl * 1).toFixed(1));
                    seRightData.push((seRecord['r' + wave] * 1 + hl * 1).toFixed(1));
                });
            }
            let seLeftChart = {
                chart: {
                    backgroundColor: 'rgba(0,0,0,0)',
                },
                title: {
                    text: '左耳听觉',
                },
                xAxis: {
                    title: {
                        text: 'Hz',
                    },
                    gridLineWidth: 1,
                    min: 0,
                    max: 80,
                    tickInterval: 12,
                    labels: {
                        formatter: function () {
                            var arr = [];
                            arr[0] = '250';
                            arr[12] = '500';
                            arr[24] = '1000';
                            arr[36] = '2000';
                            arr[48] = '4000';
                            arr[60] = '8000';
                            arr[72] = '16000';
                            return arr[this.value];
                        },
                    },
                },
                yAxis: {
                    tickInterval: 10,
                    labels: {
                        enabled: true,
                    },
                    title: {
                        text: 'dBr',
                    },
                    reversed: true,
                    min: -10,
                    max: 120,
                },
                series: [
                    {
                        data: seLeftData.map(i => i * 1),
                        name: '左耳(dBr)',
                        color: '#7cb5ec',
                    },
                ],
                credits: {
                    enabled: false, // 禁用版权信息
                },
                legend: {
                    align: 'center', //水平方向位置
                    verticalAlign: 'top', //垂直方向位置
                    x: 0, //距离x轴的距离
                    y: 20, //距离Y轴的距离
                },
                tooltip: {
                    formatter: function () {
                        let categories = [
                            '250',
                            '265',
                            '281',
                            '297',
                            '315',
                            '334',
                            '354',
                            '375',
                            '397',
                            '420',
                            '445',
                            '472',
                            '500',
                            '530',
                            '561',
                            '595',
                            '630',
                            '667',
                            '707',
                            '749',
                            '794',
                            '841',
                            '891',
                            '944',
                            '1000',
                            '1059',
                            '1122',
                            '1189',
                            '1260',
                            '1335',
                            '1414',
                            '1498',
                            '1587',
                            '1682',
                            '1782',
                            '1888',
                            '2000',
                            '2119',
                            '2245',
                            '2378',
                            '2520',
                            '2670',
                            '2828',
                            '2997',
                            '3175',
                            '3364',
                            '3564',
                            '3775',
                            '4000',
                            '4238',
                            '4490',
                            '4757',
                            '5040',
                            '5340',
                            '5657',
                            '5993',
                            '6350',
                            '6727',
                            '7127',
                            '7551',
                            '8000',
                            '8476',
                            '8980',
                            '9514',
                            '10080',
                            '10679',
                            '11314',
                            '11986',
                        ];
                        let xValue = categories[this.x];
                        return xValue + '左耳(dBr):' + this.y;
                    },
                },
            };

            let seRightChart = {
                chart: {
                    backgroundColor: 'rgba(0,0,0,0)',
                },
                title: {
                    text: '右耳听觉',
                },

                xAxis: {
                    title: {
                        text: 'Hz',
                    },
                    gridLineWidth: 1,
                    min: 0,
                    max: 80,
                    tickInterval: 12,
                    labels: {
                        formatter: function () {
                            var arr = [];
                            arr[0] = '250';
                            arr[12] = '500';
                            arr[24] = '1000';
                            arr[36] = '2000';
                            arr[48] = '4000';
                            arr[60] = '8000';
                            arr[72] = '16000';
                            return arr[this.value];
                        },
                    },
                },
                yAxis: {
                    tickInterval: 10,
                    title: {
                        text: 'dBr',
                    },
                    reversed: true,
                    min: -10,
                    max: 120,
                },
                series: [
                    {
                        data: seRightData.map(i => i * 1),
                        name: '右耳(dBr)',
                        color: '#7cb5ec',
                    },
                ],
                credits: {
                    enabled: false, // 禁用版权信息
                },
                legend: {
                    align: 'center', //水平方向位置
                    verticalAlign: 'top', //垂直方向位置
                    x: 0, //距离x轴的距离
                    y: 20, //距离Y轴的距离
                },
                tooltip: {
                    formatter: function () {
                        let categories = [
                            '250',
                            '265',
                            '281',
                            '297',
                            '315',
                            '334',
                            '354',
                            '375',
                            '397',
                            '420',
                            '445',
                            '472',
                            '500',
                            '530',
                            '561',
                            '595',
                            '630',
                            '667',
                            '707',
                            '749',
                            '794',
                            '841',
                            '891',
                            '944',
                            '1000',
                            '1059',
                            '1122',
                            '1189',
                            '1260',
                            '1335',
                            '1414',
                            '1498',
                            '1587',
                            '1682',
                            '1782',
                            '1888',
                            '2000',
                            '2119',
                            '2245',
                            '2378',
                            '2520',
                            '2670',
                            '2828',
                            '2997',
                            '3175',
                            '3364',
                            '3564',
                            '3775',
                            '4000',
                            '4238',
                            '4490',
                            '4757',
                            '5040',
                            '5340',
                            '5657',
                            '5993',
                            '6350',
                            '6727',
                            '7127',
                            '7551',
                            '8000',
                            '8476',
                            '8980',
                            '9514',
                            '10080',
                            '10679',
                            '11314',
                            '11986',
                        ];
                        let xValue = categories[this.x];
                        return xValue + '右耳(dBr):' + this.y;
                    },
                },
            };
            this.seLeftChart = seLeftChart;
            this.seRightChart = seRightChart;
        },
    },
};
</script>

<style scoped>
.invitationTop {
    padding: 20px 16px 5px 16px;
}

.invitaitonTitle {
    text-align: center;
    font-size: 20px;
    color: #00c3ae;
    background: #fff;
}

.invitaitonTop .van-image {
    margin: 0 10px;
}

.userInfoTitle .van-image {
    font-size: 48px;
    height: auto;
    line-height: 100%;
    padding-right: 10px;
    border-radius: 5px;
}
.userInfoTitle .van-cell__left-icon {
    font-size: 48px;
    height: auto;
    line-height: 100%;
}

.sex-icon {
    font-size: 24px;
}
.bottomLineNo::after {
    display: none;
}
</style>
