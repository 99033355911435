import { Method, request, Url } from '@/services/base';

async function getSeRecordList(userNo) {
    return await request(Url.seRecord.getSeRecordList, Method.get, { userNo });
}

async function getSeRecord(id) {
    return await request(Url.seRecord.getSeRecord, Method.get, { id });
}

async function getDbhlList() {
    return await request(Url.seRecord.getDbhlList, Method.get);
}

export {
    getSeRecordList,
    getSeRecord,
    getDbhlList
}